'use client';

import React, { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { useSearchParams } from 'next/navigation';
import Script from 'next/script';

import Icon, { IconProps } from '@components/Icon';
import useToast from '@components/Toast';
import { copyContent, validateEmail } from '@raise/common';
import { CommonDocument, FormsDocument, ToastsDocument } from '@raise/sanity';

declare global {
  interface Window {
    prefinery: any;
  }
}

export type WaitlistFormProps = {
  toasts: ToastsDocument;
  forms: FormsDocument;
  waitlist: CommonDocument['common']['waitlist'];
  hasSubmitted: boolean;
  setHasSubmitted: (val: boolean) => void;
  setSubmissionSuccessful: (val: boolean) => void;
  setReferralLink: (val: string) => void;
};

// Generate a referral link based on the referral code
const genWaitlistLink = (referralCode: string) =>
  `${window.location.origin}/waitlist?r=${referralCode}`;

const WaitlistForm: React.FC<WaitlistFormProps> = ({
  forms: {
    fields: { email, first_name, last_name },
  },
  waitlist: { cta },
  hasSubmitted,
  setHasSubmitted,
  setSubmissionSuccessful,
  setReferralLink,
  toasts,
}) => {
  // Store the form values
  const [emailVal, setEmailVal] = useState('');
  const [firstNameVal, setFirstNameVal] = useState('');
  const [lastNameVal, setLastNameVal] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Check if the email is valid
  const isValidEmail = emailVal !== '' && validateEmail(emailVal);

  // Get the router
  const searchParams = useSearchParams();
  const toast = useToast(toasts.waitlist_signup);

  // Get the toast
  const toastToDisplay = useToast(toasts.waitlist_signup);

  // On submit...
  const onSubmit = async () => {
    // Set that the form has been submitted
    setHasSubmitted(true);
    setIsSubmitting(true);

    // If the email is valid and the first and last name are not empty...
    if (isValidEmail && firstNameVal !== '' && lastNameVal !== '') {
      try {
        // If there is a referral code, add it to the data
        const referral_code = searchParams?.get('r');

        // Compose the data
        const data: any = {
          email: emailVal,
          first_name: firstNameVal,
          last_name: lastNameVal,
          ...(referral_code && { referral_code }),
        };

        // Add the user to prefinery

        const response = await fetch('/api/waitlist', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((res) => res.json());

        // Upon adding the user, send them to the confirmation page
        setReferralLink(genWaitlistLink(response.referral_code));
        setSubmissionSuccessful(true);

        // Show a toast
        toast.success();

        // Reset the form values
        setEmailVal('');
        setFirstNameVal('');
        setLastNameVal('');
      } catch (e) {
        toastToDisplay.error();

        console.error(e);
      }
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Script
        src="https://widget.prefinery.com/widget/v2/d8ffc2mq.js"
        strategy="afterInteractive"
        onLoad={() =>
          setTimeout(() => window.prefinery('recordFormImpression'), 1000)
        }
      />
      <FormControl>
        <FormLabel>{email.label}</FormLabel>
        <InputGroup size="lg">
          <InputLeftElement>
            <Icon
              style="solid"
              icon="envelope"
              color="gray.300"
              size="lg"
              ml={1}
            />
          </InputLeftElement>
          <Input
            placeholder={email.placeholder}
            value={emailVal}
            onChange={(e) => setEmailVal(e.target.value)}
            isInvalid={hasSubmitted && !isValidEmail}
          />
        </InputGroup>
      </FormControl>
      <Flex direction={['column', null, 'row']} mt={4} gap={4}>
        <FormControl>
          <FormLabel>{first_name.label}</FormLabel>
          <Input
            size="lg"
            placeholder={first_name.placeholder}
            value={firstNameVal}
            onChange={(e) => setFirstNameVal(e.target.value)}
            isInvalid={hasSubmitted && firstNameVal === ''}
          />
        </FormControl>
        <FormControl>
          <FormLabel>{last_name.label}</FormLabel>
          <Input
            size="lg"
            placeholder={last_name.placeholder}
            value={lastNameVal}
            onChange={(e) => setLastNameVal(e.target.value)}
            isInvalid={hasSubmitted && lastNameVal === ''}
          />
        </FormControl>
      </Flex>
      <Button
        disabled={isSubmitting}
        isLoading={isSubmitting}
        onClick={onSubmit}
        size="lg"
        mt={6}
        display="block"
        mx="auto"
      >
        {cta}
      </Button>
    </>
  );
};

// Get unique URL's to all channels based on the text and referral code
const genChannels = (text: string, url: string) => [
  {
    name: 'Facebook',
    icon: {
      style: 'brands',
      icon: 'facebook',
    },
    url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
      url,
    )}&quote=${encodeURI(text)}`,
  },
  {
    name: 'Twitter',
    icon: {
      style: 'brands',
      icon: 'twitter',
    },
    url: `https://twitter.com/intent/tweet?text=${encodeURI(
      text,
    )}&url=${encodeURI(url)}`,
  },
  {
    name: 'WhatsApp',
    icon: {
      style: 'brands',
      icon: 'whatsapp',
    },
    url: `https://wa.me/?text=${encodeURI(text + ' - ' + url)}`,
  },
  {
    name: 'LinkedIn',
    icon: {
      style: 'brands',
      icon: 'linkedin',
    },
    url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
      url,
    )}&title=${encodeURI(text)}`,
  },
  {
    name: 'Copy Link',
    icon: {
      style: 'solid',
      icon: 'link',
    },
    fn: () => copyContent(url),
  },
];

const Channel = ({
  name,
  icon,
  url,
  fn,
}: {
  name: string;
  icon: IconProps;
  url?: string;
  fn?: () => void;
}) => {
  // If the channel is a function, and not a link, render a button
  if (fn) {
    return (
      <IconButton
        aria-label={name}
        as="button"
        onClick={fn}
        borderRadius="full"
        colorScheme="gray"
        icon={<Icon {...icon} />}
      />
    );
  }

  if (!url) return null;

  // Otherwise, render a link
  return (
    <IconButton
      aria-label={name}
      as={NextLink}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      borderRadius="full"
      colorScheme="gray"
      icon={<Icon {...icon} />}
    />
  );
};

const WaitlistConfirmation: React.FC<
  CommonDocument['common']['waitlist'] & { referralLink: string }
> = ({ confirmation, referralLink }) => (
  <>
    <Divider borderColor="gray.400" my={8} />
    <Flex direction="column" textAlign="center" mt={4}>
      <Heading as="h3" size="lg" textAlign="center" mb={4}>
        {confirmation.title}
      </Heading>
      <Text>{confirmation.description}</Text>
      <Flex mt={4} gap={4} justify="center" align="center">
        {genChannels(confirmation.share_text, referralLink).map((channel) => (
          <Channel key={channel.name} {...channel} />
        ))}
      </Flex>
    </Flex>
  </>
);

const Waitlist = ({
  waitlist,
  forms,
  toasts,
}: {
  waitlist: CommonDocument['common']['waitlist'];
  forms: FormsDocument;
  toasts: ToastsDocument;
}) => {
  // Store the submission state and the referral link
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [submissionSuccessful, setSubmissionSuccessful] = useState(false);
  const [referralLink, setReferralLink] = useState('');

  // If we have submitted and the submission was successful, show the confirmation
  if (hasSubmitted && submissionSuccessful && referralLink !== '') {
    return <WaitlistConfirmation {...waitlist} referralLink={referralLink} />;
  }

  // Otherwise, show the form
  return (
    <WaitlistForm
      waitlist={waitlist}
      forms={forms}
      hasSubmitted={hasSubmitted}
      setHasSubmitted={setHasSubmitted}
      setSubmissionSuccessful={setSubmissionSuccessful}
      setReferralLink={setReferralLink}
      toasts={toasts}
    />
  );
};

export default Waitlist;
